import { render, staticRenderFns } from "./newsForm.vue?vue&type=template&id=fd1d0590&scoped=true&"
import script from "./newsForm.vue?vue&type=script&lang=js&"
export * from "./newsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd1d0590",
  null
  
)

export default component.exports